var limitedUseHardcodedStrings = {
	"@id": "/v1.0/IT/en-GB/messages?code=*hardcoded*&ignoreCulture=true",
	"@type": "hydra:Collection",
	"hydra:totalItems": 90,
	"hydra:member": [
	{
		culture: "de-DE",
		code: "manual.hardcoded.generic_error",
		message: "Hoppla! Ein Fehler ist aufgetreten"
	},
	{
		culture: "de-AT",
		code: "manual.hardcoded.generic_error",
		message: "Hoppla! Ein Fehler ist aufgetreten"
	},
	{
		culture: "de-CH",
		code: "manual.hardcoded.generic_error",
		message: "Hoppla! Ein Fehler ist aufgetreten"
	},
	{
		culture: "en-GB",
		code: "manual.hardcoded.generic_error",
		message: "Oops! An error has occurred"
	},
	{
		culture: "ar-AE",
		code: "manual.hardcoded.generic_error",
		message: "Oops! An error has occurred"
	},
	{
		culture: "en-AU",
		code: "manual.hardcoded.generic_error",
		message: "Oops! An error has occurred"
	},
	{
		culture: "en-CA",
		code: "manual.hardcoded.generic_error",
		message: "Oops! An error has occurred"
	},
	{
		culture: "en-IE",
		code: "manual.hardcoded.generic_error",
		message: "Oops! An error has occurred"
	},
	{
		culture: "en-US",
		code: "manual.hardcoded.generic_error",
		message: "Oops! An error has occurred"
	},
	{
		culture: "en-ZA",
		code: "manual.hardcoded.generic_error",
		message: "Oops! An error has occurred"
	},
	{
		culture: "es-ES",
		code: "manual.hardcoded.generic_error",
		message: "Ups! Se ha producido un error imprevisto"
	},
	{
		culture: "es-AR",
		code: "manual.hardcoded.generic_error",
		message: "Ups! Se ha producido un error imprevisto"
	},
	{
		culture: "es-CL",
		code: "manual.hardcoded.generic_error",
		message: "Ups! Se ha producido un error imprevisto"
	},
	{
		culture: "es-CO",
		code: "manual.hardcoded.generic_error",
		message: "Ups! Se ha producido un error imprevisto"
	},
	{
		culture: "es-PE",
		code: "manual.hardcoded.generic_error",
		message: "Ups! Se ha producido un error imprevisto"
	},
	{
		culture: "fr-FR",
		code: "manual.hardcoded.generic_error",
		message: "Oops! Une erreur est survenue"
	},
	{
		culture: "fr-BE",
		code: "manual.hardcoded.generic_error",
		message: "Oops! Une erreur est survenue"
	},
	{
		culture: "fr-CA",
		code: "manual.hardcoded.generic_error",
		message: "Oops! Une erreur est survenue"
	},
	{
		culture: "fr-CH",
		code: "manual.hardcoded.generic_error",
		message: "Oops! Une erreur est survenue"
	},
	{
		culture: "it-IT",
		code: "manual.hardcoded.generic_error",
		message: "Oops! Si è verificato un errore imprevisto"
	},
	{
		culture: "it-CH",
		code: "manual.hardcoded.generic_error",
		message: "Oops! Si è verificato un errore imprevisto"
	},
	{
		culture: "pt-BR",
		code: "manual.hardcoded.generic_error",
		message: "Opa! Ocorreu um erro imprevisto"
	},
	{
		culture: "pt-PT",
		code: "manual.hardcoded.generic_error",
		message: "Opa! Ocorreu um erro imprevisto"
	},
	{
		culture: "ru-RU",
		code: "manual.hardcoded.generic_error",
		message: "К сожалению! произошла ошибка"
	},
	{
		culture: "en-GB",
		code: "manual.hardcoded.invalid_token",
		message: "The session has expired, please reload the page."
	},
	{
		culture: "ar-AE",
		code: "manual.hardcoded.invalid_token",
		message: "The session has expired, please reload the page."
	},
	{
		culture: "en-AU",
		code: "manual.hardcoded.invalid_token",
		message: "The session has expired, please reload the page."
	},
	{
		culture: "en-CA",
		code: "manual.hardcoded.invalid_token",
		message: "The session has expired, please reload the page."
	},
	{
		culture: "en-IE",
		code: "manual.hardcoded.invalid_token",
		message: "The session has expired, please reload the page."
	},
	{
		culture: "en-US",
		code: "manual.hardcoded.invalid_token",
		message: "The session has expired, please reload the page."
	},
	{
		culture: "en-ZA",
		code: "manual.hardcoded.invalid_token",
		message: "The session has expired, please reload the page."
	},
	{
		culture: "it-IT",
		code: "manual.hardcoded.invalid_token",
		message: "La sessione e' scaduta, aggiorna la pagina."
	},
	{
		culture: "it-CH",
		code: "manual.hardcoded.invalid_token",
		message: "La sessione e' scaduta, aggiorna la pagina."
	},
	{
		culture: "fr-FR",
		code: "manual.hardcoded.invalid_token",
		message: "La session a expiré. Veuillez rafraîchir la page."
	},
	{
		culture: "fr-CA",
		code: "manual.hardcoded.invalid_token",
		message: "La session a expiré. Veuillez rafraîchir la page."
	},
	{
		culture: "fr-CH",
		code: "manual.hardcoded.invalid_token",
		message: "La session a expiré. Veuillez rafraîchir la page."
	},
	{
		culture: "de-DE",
		code: "manual.hardcoded.invalid_token",
		message: "Die Session ist abgelaufen, bitte aktualisiere die Seite."
	},
	{
		culture: "de-AT",
		code: "manual.hardcoded.invalid_token",
		message: "Die Session ist abgelaufen, bitte aktualisiere die Seite."
	},
	{
		culture: "de-CH",
		code: "manual.hardcoded.invalid_token",
		message: "Die Session ist abgelaufen, bitte aktualisiere die Seite."
	},
	{
		culture: "es-ES",
		code: "manual.hardcoded.invalid_token",
		message: "La sesión ha caducado, recarga la página."
	},
	{
		culture: "es-CL",
		code: "manual.hardcoded.invalid_token",
		message: "La sesión ha caducado, recarga la página."
	},
	{
		culture: "es-AR",
		code: "manual.hardcoded.invalid_token",
		message: "La sesión ha caducado, recarga la página."
	},
	{
		culture: "pt-BR",
		code: "manual.hardcoded.invalid_token",
		message: "A sessão expirou, por favor recarregue a página."
	},
	{
		culture: "ru-RU",
		code: "manual.hardcoded.invalid_token",
		message: "Ваша сессия истекла. Пожалуйста, перезагрузите страницу."
	},
	{
		culture: "de-DE",
		code: "manual.hardcoded.maintenance_page",
		message: "Die Seite ist vorübergehend nicht verfügbar: Versuche es erneut in einigen Minuten oder lade die App herunter."
	},
	{
		culture: "de-AT",
		code: "manual.hardcoded.maintenance_page",
		message: "Die Seite ist vorübergehend nicht verfügbar: Versuche es erneut in einigen Minuten oder lade die App herunter."
	},
	{
		culture: "de-CH",
		code: "manual.hardcoded.maintenance_page",
		message: "Die Seite ist vorübergehend nicht verfügbar: Versuche es erneut in einigen Minuten oder lade die App herunter."
	},
	{
		culture: "en-GB",
		code: "manual.hardcoded.maintenance_page",
		message: "The service is temporarily unavailable: try again in a few minutes or download the app."
	},
	{
		culture: "ar-AE",
		code: "manual.hardcoded.maintenance_page",
		message: "The service is temporarily unavailable: try again in a few minutes or download the app."
	},
	{
		culture: "en-AU",
		code: "manual.hardcoded.maintenance_page",
		message: "The service is temporarily unavailable: try again in a few minutes or download the app."
	},
	{
		culture: "en-CA",
		code: "manual.hardcoded.maintenance_page",
		message: "The service is temporarily unavailable: try again in a few minutes or download the app."
	},
	{
		culture: "en-IE",
		code: "manual.hardcoded.maintenance_page",
		message: "The service is temporarily unavailable: try again in a few minutes or download the app."
	},
	{
		culture: "en-US",
		code: "manual.hardcoded.maintenance_page",
		message: "The service is temporarily unavailable: try again in a few minutes or download the app."
	},
	{
		culture: "en-ZA",
		code: "manual.hardcoded.maintenance_page",
		message: "The service is temporarily unavailable: try again in a few minutes or download the app."
	},
	{
		culture: "es-ES",
		code: "manual.hardcoded.maintenance_page",
		message: "Temporalmente fuera de servicio, vuelve a intentarlo más tarde o descarga la app."
	},
	{
		culture: "es-AR",
		code: "manual.hardcoded.maintenance_page",
		message: "Temporalmente fuera de servicio, vuelve a intentarlo más tarde o descarga la app."
	},
	{
		culture: "es-CL",
		code: "manual.hardcoded.maintenance_page",
		message: "Temporalmente fuera de servicio, vuelve a intentarlo más tarde o descarga la app."
	},
	{
		culture: "es-CO",
		code: "manual.hardcoded.maintenance_page",
		message: "Temporalmente fuera de servicio, vuelve a intentarlo más tarde o descarga la app."
	},
	{
		culture: "es-PE",
		code: "manual.hardcoded.maintenance_page",
		message: "Temporalmente fuera de servicio, vuelve a intentarlo más tarde o descarga la app."
	},
	{
		culture: "fr-FR",
		code: "manual.hardcoded.maintenance_page",
		message: "Le service est temporairement indisponible: réessayez dans quelques minutes ou téléchargez l'application."
	},
	{
		culture: "fr-BE",
		code: "manual.hardcoded.maintenance_page",
		message: "Le service est temporairement indisponible: réessayez dans quelques minutes ou téléchargez l'application."
	},
	{
		culture: "fr-CA",
		code: "manual.hardcoded.maintenance_page",
		message: "Le service est temporairement indisponible: réessayez dans quelques minutes ou téléchargez l'application."
	},
	{
		culture: "fr-CH",
		code: "manual.hardcoded.maintenance_page",
		message: "Le service est temporairement indisponible: réessayez dans quelques minutes ou téléchargez l'application."
	},
	{
		culture: "it-IT",
		code: "manual.hardcoded.maintenance_page",
		message: "Servizio temporaneamente non disponibile: prova di nuovo tra qualche minuto o scarica l’App."
	},
	{
		culture: "it-CH",
		code: "manual.hardcoded.maintenance_page",
		message: "Servizio temporaneamente non disponibile: prova di nuovo tra qualche minuto o scarica l’App."
	},
	{
		culture: "pt-BR",
		code: "manual.hardcoded.maintenance_page",
		message: "O serviço está temporariamente indisponível: tente novamente em alguns minutos ou faça o download do app."
	},
	{
		culture: "pt-PT",
		code: "manual.hardcoded.maintenance_page",
		message: "O serviço está temporariamente indisponível: tente novamente em alguns minutos ou faça o download do app."
	},
	{
		culture: "ru-RU",
		code: "manual.hardcoded.maintenance_page",
		message: "Служба временно недоступна: повторите попытку через несколько минут или загрузите приложение."
	},
	{
		culture: "de-DE",
		code: "manual.hardcoded.network_loading_failure",
		message: "Es tut uns leid, dein Browser konnte die Seite nicht laden. Überprüfe deine Verbindung und klicke hier, um es erneut zu versuchen."
	},
	{
		culture: "de-AT",
		code: "manual.hardcoded.network_loading_failure",
		message: "Es tut uns leid, dein Browser konnte die Seite nicht laden. Überprüfe deine Verbindung und klicke hier, um es erneut zu versuchen."
	},
	{
		culture: "de-CH",
		code: "manual.hardcoded.network_loading_failure",
		message: "Es tut uns leid, dein Browser konnte die Seite nicht laden. Überprüfe deine Verbindung und klicke hier, um es erneut zu versuchen."
	},
	{
		culture: "en-GB",
		code: "manual.hardcoded.network_loading_failure",
		message: "We are sorry but your browser could not load the website or the website has recently been updated. Please check your connection and click here to try again."
	},
	{
		culture: "ar-AE",
		code: "manual.hardcoded.network_loading_failure",
		message: "We are sorry but your browser could not load the website or the website has recently been updated. Please check your connection and click here to try again."
	},
	{
		culture: "en-AU",
		code: "manual.hardcoded.network_loading_failure",
		message: "We are sorry but your browser could not load the website or the website has recently been updated. Please check your connection and click here to try again."
	},
	{
		culture: "en-CA",
		code: "manual.hardcoded.network_loading_failure",
		message: "We are sorry but your browser could not load the website or the website has recently been updated. Please check your connection and click here to try again."
	},
	{
		culture: "en-IE",
		code: "manual.hardcoded.network_loading_failure",
		message: "We are sorry but your browser could not load the website or the website has recently been updated. Please check your connection and click here to try again."
	},
	{
		culture: "en-US",
		code: "manual.hardcoded.network_loading_failure",
		message: "We are sorry but your browser could not load the website or the website has recently been updated. Please check your connection and click here to try again."
	},
	{
		culture: "en-ZA",
		code: "manual.hardcoded.network_loading_failure",
		message: "We are sorry but your browser could not load the website or the website has recently been updated. Please check your connection and click here to try again."
	},
	{
		culture: "es-ES",
		code: "manual.hardcoded.network_loading_failure",
		message: "Lo sentimos, tu navegador no ha podido cargar el sitio. Verifica tu conexión y clica aquí para volver a intentarlo."
	},
	{
		culture: "es-AR",
		code: "manual.hardcoded.network_loading_failure",
		message: "Lo sentimos, tu navegador no ha podido cargar el sitio. Verifica tu conexión y clica aquí para volver a intentarlo."
	},
	{
		culture: "es-CL",
		code: "manual.hardcoded.network_loading_failure",
		message: "Lo sentimos, tu navegador no ha podido cargar el sitio. Verifica tu conexión y clica aquí para volver a intentarlo."
	},
	{
		culture: "es-CO",
		code: "manual.hardcoded.network_loading_failure",
		message: "Lo sentimos, tu navegador no ha podido cargar el sitio. Verifica tu conexión y clica aquí para volver a intentarlo."
	},
	{
		culture: "es-PE",
		code: "manual.hardcoded.network_loading_failure",
		message: "Lo sentimos, tu navegador no ha podido cargar el sitio. Verifica tu conexión y clica aquí para volver a intentarlo."
	},
	{
		culture: "fr-FR",
		code: "manual.hardcoded.network_loading_failure",
		message: "Nous sommes désolés, votre navigateur n'a pas pu charger le site. Vérifiez votre connexion et cliquez ici pour réessayer."
	},
	{
		culture: "fr-BE",
		code: "manual.hardcoded.network_loading_failure",
		message: "Nous sommes désolés, votre navigateur n'a pas pu charger le site. Vérifiez votre connexion et cliquez ici pour réessayer."
	},
	{
		culture: "fr-CA",
		code: "manual.hardcoded.network_loading_failure",
		message: "Nous sommes désolés, votre navigateur n'a pas pu charger le site. Vérifiez votre connexion et cliquez ici pour réessayer."
	},
	{
		culture: "fr-CH",
		code: "manual.hardcoded.network_loading_failure",
		message: "Nous sommes désolés, votre navigateur n'a pas pu charger le site. Vérifiez votre connexion et cliquez ici pour réessayer."
	},
	{
		culture: "it-IT",
		code: "manual.hardcoded.network_loading_failure",
		message: "Ci dispiace, il tuo browser non è riuscito a caricare il sito. Controlla la tua connessione e clicca quì per riprovare."
	},
	{
		culture: "it-CH",
		code: "manual.hardcoded.network_loading_failure",
		message: "Ci dispiace, il tuo browser non è riuscito a caricare il sito. Controlla la tua connessione e clicca quì per riprovare."
	},
	{
		culture: "pt-BR",
		code: "manual.hardcoded.network_loading_failure",
		message: "Lamentamos, mas o seu navegador não pôde carregar o site ou o site foi atualizado recentemente. Por favor, verifique sua conexão e clique aqui para tentar novamente."
	},
	{
		culture: "pt-PT",
		code: "manual.hardcoded.network_loading_failure",
		message: "Lamentamos, mas o seu navegador não pôde carregar o site ou o site foi atualizado recentemente. Por favor, verifique sua conexão e clique aqui para tentar novamente."
	},
	{
		culture: "ru-RU",
		code: "manual.hardcoded.network_loading_failure",
		message: "К сожалению, ваш браузер не смог загрузить веб-сайт или веб-сайт был недавно обновлен. Пожалуйста, проверьте ваше соединение и нажмите здесь, чтобы повторить попытку."
	},
	{
		culture: "en-GB",
		code: "manual.hardcoded.refresh_page_button",
		message: "Refresh page"
	},
	{
		culture: "ar-AE",
		code: "manual.hardcoded.refresh_page_button",
		message: "Refresh page"
	},
	{
		culture: "en-AU",
		code: "manual.hardcoded.refresh_page_button",
		message: "Refresh page"
	},
	{
		culture: "en-CA",
		code: "manual.hardcoded.refresh_page_button",
		message: "Refresh page"
	},
	{
		culture: "en-IE",
		code: "manual.hardcoded.refresh_page_button",
		message: "Refresh page"
	},
	{
		culture: "en-US",
		code: "manual.hardcoded.refresh_page_button",
		message: "Refresh page"
	},
	{
		culture: "en-ZA",
		code: "manual.hardcoded.refresh_page_button",
		message: "Refresh page"
	},
	{
		culture: "it-IT",
		code: "manual.hardcoded.refresh_page_button",
		message: "Aggiorna pagina"
	},
	{
		culture: "it-CH",
		code: "manual.hardcoded.refresh_page_button",
		message: "Aggiorna pagina"
	},
	{
		culture: "fr-FR",
		code: "manual.hardcoded.refresh_page_button",
		message: "Rafraîchir la page"
	},
	{
		culture: "fr-CH",
		code: "manual.hardcoded.refresh_page_button",
		message: "Rafraîchir la page"
	},
	{
		culture: "fr-CA",
		code: "manual.hardcoded.refresh_page_button",
		message: "Rafraîchir la page"
	},
	{
		culture: "de-DE",
		code: "manual.hardcoded.refresh_page_button",
		message: "Seite aktualisieren"
	},
	{
		culture: "de-AT",
		code: "manual.hardcoded.refresh_page_button",
		message: "Seite aktualisieren"
	},
	{
		culture: "de-CH",
		code: "manual.hardcoded.refresh_page_button",
		message: "Seite aktualisieren"
	},
	{
		culture: "es-ES",
		code: "manual.hardcoded.refresh_page_button",
		message: "Recarga la página"
	},
	{
		culture: "es-CO",
		code: "manual.hardcoded.refresh_page_button",
		message: "Recarga la página"
	},
	{
		culture: "es-AR",
		code: "manual.hardcoded.refresh_page_button",
		message: "Recarga la página"
	},
	{
		culture: "es-CL",
		code: "manual.hardcoded.refresh_page_button",
		message: "Recarga la página"
	},
	{
		culture: "pt-BR",
		code: "manual.hardcoded.refresh_page_button",
		message: "Atualizar a página"
	},
	{
		culture: "ru-RU",
		code: "manual.hardcoded.refresh_page_button",
		message: "Перезагрузить страницу"
	}
]
};

export { limitedUseHardcodedStrings };
