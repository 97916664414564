import 'prop-types';
import '@dbh/generic-types';
import '@dbh/request-types';
import { API_IDS_TO_VERSIONS, API_IDS, HTTP_STATUS_CODES } from '@dbh/api-constants';
import { isRelativeUrl, getUrlPartsFromRelativeUrl } from '@dbh/urls';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { Map } from 'immutable';
import makeApiUrl from '@dbh/make-api-url';
import { request } from '@dbh/request';
import { requestQueued } from '@dbh/request-queue-redux';
import { makeRequestsToFrontEndApiCallsLogsApi } from '@dbh/front-end-api-calls-logs';
import 'lodash/takeRight';
import '@dbh/validation';

/*
 *
 * Constants: `@dbh/circular-webpages-redirect-checker`.
 *
 */const DEFAULT_MAXIMUM_REDIRECTS_TO_FOLLOW=5;const API_FIELDS_FILTER=["redirectTo","statusCode"];

const{webpages:WEBPAGES_API_ID}=API_IDS,API_VERSION=API_IDS_TO_VERSIONS[WEBPAGES_API_ID]||API_IDS_TO_VERSIONS.default,createWebpagesApiRequest=withConformsTo("createWebpagesApiRequest",[],async a=>{let{country:b,locale:c,webpageUrl:d,requestOptions:e,store:f}=a;const g={url:d,_makeUrlRelative:!0,_fields:API_FIELDS_FILTER.join(",")},{env:h,frontEndApiDomainCookieValue:i}=e,j={encodeQueryWithRison:!1,queryParameters:Map(g),routeParameters:Map({locale:c,country:b,apiVersion:API_VERSION}),frontEndApiEnv:h,forcedFrontEndApiDomain:i},k=makeApiUrl(WEBPAGES_API_ID,j,!0),l=await request(k,e),{emptyResponse:m,apiResponse:n,timings:o,requestAttributes:p,fetchOptions:q,responseHeaders:r}=l,s=makeRequestsToFrontEndApiCallsLogsApi({apiResponse:n,responseHeaders:r,url:k,timings:o,requestAttributes:p,fetchOptions:q,isEmptyApiResponse:m});if(f.dispatch(requestQueued(...s)),!m)// Can happen in the tests if:
// 1) `jest-fetch-mock` was enabled globally with `enableMocks` in the
// setup files of the tests.
// 2) Given 1), `fetch` was not unmocked with `fetchMock.dontMock()` in
// the test file, nor a specific mock implementation was applied with the
// available mock methods.
// @see {@link https://github.com/jefflau/jest-fetch-mock#to-setup-for-all-tests}
{const a=n["hydra:member"];return a?a[0]:void 0}});/**
 * Returns the `API` response of the given `webpages` `URL`.
 * @param {Object} options .
 * @param {string} options.country .
 * @param {string} options.locale .
 * @param {string} options.webpageUrl .
 * @param {Object?} options.requestOptions .
 * @param {Object} options.store .
 * @return {Object?} .
 * @see {@link https://apidev.daybreakhotels.com/v1.2/IT/en-GB/webpages?url=/IT/en-GB/1/dbh-test-only-do-not-delete/lacus&_makeUrlRelative=true}
 * @see {@link https://apidev.daybreakhotels.com/v1.2/IT/en-GB/webpages?url=/IT/en-GB/1/dbh-test-only-do-not-delete/lorem&_makeUrlRelative=true}
 */var createWebpagesApiRequest$1 = createWebpagesApiRequest;

/**
 * Returns a formatted message, ordered list of the given `URLs`.
 * @param {string[]} urls .
 * @return {string} .
 */const createOrderedUrlsList=withConformsTo("createOrderedUrlsList",[],a=>a.reduce((a,b,c)=>{let d=a;return d+=c+1+": "+b+"\n",d},""));var createOrderedUrlsList$1 = createOrderedUrlsList;

class CircularRedirectDetectedError extends Error{constructor(a){let b="";b="Detected circular redirect (`admindev` misconfiguration): "+a.join(", ");super(b),this.name=CircularRedirectDetectedError.getClassName();}}CircularRedirectDetectedError.getClassName=()=>"CircularRedirectDetectedError";var CircularRedirectDetectedError$1 = CircularRedirectDetectedError;

class RedirectLoopDetectedError extends Error{constructor(a){let b="";b="Detected redirect loop (`admindev` misconfiguration): "+a.join(", ");super(b),this.name=RedirectLoopDetectedError.getClassName();}}RedirectLoopDetectedError.getClassName=()=>"RedirectLoopDetectedError";var RedirectLoopDetectedError$1 = RedirectLoopDetectedError;

const {PERMANENT_REDIRECT,TEMPORARY_REDIRECT}=HTTP_STATUS_CODES,circularWebpagesRedirectChecker=withConformsTo("circularWebpagesRedirectChecker",[],async(a,b)=>{let{country:c,locale:d,originalUrl:e,originalUrlRedirectsTo:f,requestOptions:g,redirects:i=[],store:h}=a;const j=!isRelativeUrl(f);if(j)return !1;// Not using `limit=DEFAULT_MAXIMUM_REDIRECTS_TO_FOLLOW` because of a current
// limitation of `withConformsTo`.
const k="undefined"==typeof b?DEFAULT_MAXIMUM_REDIRECTS_TO_FOLLOW:b,{pathname:l}=getUrlPartsFromRelativeUrl(e),{pathname:m}=getUrlPartsFromRelativeUrl(f),n=[...i,l],o=n.includes(m);if(o)throw new CircularRedirectDetectedError$1([...n,m]);const p=await createWebpagesApiRequest$1({country:c,locale:d,webpageUrl:m,requestOptions:g,store:h});if(p){const{statusCode:a,redirectTo:b}=p;if(1===k)throw new RedirectLoopDetectedError$1([...n,m,b]);[PERMANENT_REDIRECT,TEMPORARY_REDIRECT].includes(a)&&(await circularWebpagesRedirectChecker({country:c,locale:d,originalUrl:m,originalUrlRedirectsTo:b,redirects:n,requestOptions:g,store:h},k-1));}return !1});

export { API_FIELDS_FILTER, CircularRedirectDetectedError$1 as CircularRedirectDetectedError, DEFAULT_MAXIMUM_REDIRECTS_TO_FOLLOW, RedirectLoopDetectedError$1 as RedirectLoopDetectedError, createOrderedUrlsList$1 as createOrderedUrlsList, createWebpagesApiRequest$1 as createWebpagesApiRequest, circularWebpagesRedirectChecker as default };
